const trackAssets = [
   { id: 1, imgSrc: "/assets/products/SC-10001-S400.svg", category: "S", WD: 120, title: "SC-10001" },
   { id: 2, imgSrc: "/assets/products/SC-10005-S200.svg", category: "S", WD: 120, title: "SC-10005" },
   { id: 3, imgSrc: "/assets/products/SC-10006-S100.svg", category: "S", WD: 120, title: "SC-10006" },
   { id: 4, imgSrc: "/assets/products/SC-10007-R2_45.svg", category: "R", WD: 127, title: "SC-10007" },
   { id: 5, imgSrc: "/assets/products/SC-10008-R1_45.svg", category: "R", WD: 127, title: "SC-10008" },

   { id: 6, imgSrc: "/assets/products/SC-10009-R3_45.svg", category: "R", WD: 127, title: "SC-10009" },
   { id: 7, imgSrc: "/assets/products/SC-10010-R4_225.svg", category: "R", WD: 127, title: "SC-10010" },
   { id: 8, imgSrc: "/assets/products/SC-10011-S200C.svg", category: "S", WD: 120, title: "SC-10011" },
   { id: 9, imgSrc: "/assets/products/SC-10012-R1_225.svg", category: "R", WD: 127, title: "SC-10012" },
   { id: 10, imgSrc: "/assets/products/SC-10013-R2_225.svg", category: "R", WD: 127, title: "SC-10013" },
   { id: 11, imgSrc: "/assets/products/SC-10014-R3_225.svg", category: "R", WD: 127, title: "SC-10014" },
   { id: 12, imgSrc: "/assets/products/SC-10015-R5_225.svg", category: "R", WD: 127, title: "SC-10015" },
   { id: 13, imgSrc: "/assets/products/SC-10016-R6_225.svg", category: "R", WD: 127, title: "SC-10016" },
   { id: 14, imgSrc: "/assets/products/SC-10017-S400x.svg", category: "S", WD: 120, title: "SC-10017" },
   { id: 15, imgSrc: "/assets/products/SC-10101-BI_R1_45.svg", category: "B", WD: 127, title: "SC-10101" },
   { id: 16, imgSrc: "/assets/products/SC-10102-BO_R1_45.svg", category: "B", WD: 127, title: "SC-10102" },
   { id: 17, imgSrc: "/assets/products/SC-10103-BI_R1_225.svg", category: "B", WD: 127, title: "SC-10103" },
   { id: 18, imgSrc: "/assets/products/SC-10104-BO_R1_225.svg", category: "B", WD: 127, title: "SC-10104" },
   { id: 19, imgSrc: "/assets/products/SC-10105-BI_R2_45.svg", category: "B", WD: 127, title: "SC-10105" },
   { id: 20, imgSrc: "/assets/products/SC-10106-BI_R2_225.svg", category: "B", WD: 127, title: "SC-10106" },
   { id: 21, imgSrc: "/assets/products/SC-10107-BO_R2_225.svg", category: "B", WD: 127, title: "SC-10107" },
   { id: 22, imgSrc: "/assets/products/SC-10108-BI_R3_225.svg", category: "B", WD: 127, title: "SC-10108" },
   { id: 23, imgSrc: "/assets/products/SC-10109-BO_R3_225.svg", category: "B", WD: 127, title: "SC-10109" },
   { id: 24, imgSrc: "/assets/products/SC-10110-BI_R4_225.svg", category: "B", WD: 127, title: "SC-10110" },
   { id: 25, imgSrc: "/assets/products/SC-10111-BO_R4_1125.svg", category: "B", WD: 127, title: "SC-10111" },
   { id: 26, imgSrc: "/assets/products/SC-10112-BI_R5_1125.svg", category: "B", WD: 127, title: "SC-10112" },
   { id: 27, imgSrc: "/assets/products/SC-10113-BO_R5_1125.svg", category: "B", WD: 127, title: "SC-10113" },
   { id: 28, imgSrc: "/assets/products/SC-10114-BI_R6_1125.svg", category: "B", WD: 127, title: "SC-10114" },
   { id: 29, imgSrc: "/assets/products/SC-10115-BO_R6_1125.svg", category: "B", WD: 127, title: "SC-10115" },
   { id: 30, imgSrc: "/assets/products/SC-10116-B_S200.svg", category: "B", WD: 120, title: "SC-10116" },
   { id: 31, imgSrc: "/assets/products/SC-10117-B_S200closer.svg", category: "B", WD: 120, title: "SC-10117" },
];
export default trackAssets;