import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import asyncComponent from './util/asyncComponent';
import SignIn from './routes/SignIn';
import SignUp from './routes/SignUp';
import Error404 from './routes/Error404';
import ChangePassword from './routes/ChangePassword';
import { getUser, setInitUrl } from './actions';
import clsx from 'clsx';
import RequestPassword from './routes/RequestPassword';
import RequestPasswordSent from './routes/RequestPasswordSent';
import RequestPasswordExpired from './routes/RequestPasswordExpired';

const RestrictedRoute = ({ component: Component, token, ...rest }) => {
   return (
      <Route
         {...rest}
         render={props =>
            token
               ? <Component {...props} />
               : <Redirect
                  to={{
                     pathname: '/login',
                     state: { from: props.location }
                  }}
               />}
      />
   );
}

class App extends Component {
   constructor(props) {
      super(props);

      if (this.props.initURL === '') {
         this.props.setInitUrl(this.props.history.location.pathname);
      }
   }
   componentDidMount() {
      if (this.props.token) this.props.getUser();
   }
   render() {
      const { token } = this.props;
      const isSideShowPath = this.props.location.pathname.includes("/app");
      return (
         <React.Fragment>
            <div id="page-container" className={clsx(isSideShowPath && "sidebar-o", "sidebar-dark page-header-dark sidebar-mini enable-page-overlay side-scroll")}>
               <Switch>
                  <Redirect exact path="/" to="/app" />
                  <RestrictedRoute path='/app' token={token} component={asyncComponent(() => import("./routes/AppLayout"))} />
                  <RestrictedRoute path='/profile' token={token} component={asyncComponent(() => import("./routes/Profile"))} />
                  <RestrictedRoute path='/store' token={token} component={asyncComponent(() => import("./routes/Store"))} />
                  <RestrictedRoute path='/design-ideas' token={token} component={asyncComponent(() => import("./routes/Store"))} />
                  {/* <Route path='/app' component={asyncComponent(() => import("./routes/AppLayout"))} /> */}
                  <Route path='/demo' component={asyncComponent(() => import("./routes/Demo"))} />

                  <Route exact path='/login' component={SignIn} />
                  <Route exact path='/register' component={SignUp} />
                  <Route exact path='/request-password' component={RequestPassword} />
                  <Route exact path='/request-password/sent' component={RequestPasswordSent} />
                  <Route exact path='/request-password/new/expired' component={RequestPasswordExpired} />
                  <Route exact path='/request-password/change/:entoken' component={ChangePassword} />

                  <Route component={Error404} />
               </Switch>
            </div>
         </React.Fragment>
      );
   }
}

const mapStateToProps = ({ auth }) => {
   const { authUser, token, initURL } = auth;
   return { token, authUser, initURL }
};
const mapDispatchToProps = { setInitUrl, getUser };
export default connect(mapStateToProps, mapDispatchToProps)(App);