import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Auth from './Auth';
import Common from './Common';
import AlertMessage from './AlertMessage';
import Tracks from './Tracks';
import Designs from './Designs';
import Floorplan from './Floorplan';
import WorkArea from './WorkArea';
import Controller from './Controller';
import Inventory from './Inventory';
import Moveable from './Moveable';

export default (history) => combineReducers({
   router: connectRouter(history),
   auth: Auth,
   commonData: Common,
   alertMessage: AlertMessage,
   tracks: Tracks,
   designs: Designs,
   floorplanSetting: Floorplan,
   workAreaSetting: WorkArea,
   controllerSetting: Controller,
   inventorySetting: Inventory,
   moveableInstance: Moveable,
});
