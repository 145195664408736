import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userSignUp } from '../actions/Auth';

const SignUp = (props) => {
   const dispatch = useDispatch();
   const history = useHistory();
   const loading = useSelector(state => state.commonData.loading);
   const [firstName, setFirstName] = useState("");
   const [lastName, setLastName] = useState("");
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const onHandleSubmit = () => {
      if (
         firstName === ""
         || lastName === ""
         || email === ""
         || password === ""
      ) {
         toast.success("Please fill up all fields.");
         return;
      }
      const payload = { firstName, lastName, email, password };
      dispatch(userSignUp(payload, history));
   }
   return (
      <main id="main-container">
         <div className="row no-gutters">
            {/* Main Section */}
            <div className="hero-static col-md-12 d-flex align-items-center bg-white">
               <div className="container p-3 w-100">
                  {/* Header */}
                  <div className="mb-3 text-center">
                     <Link to="/" className="d-flex justify-content-center mb-2">
                        <img src="/logo-180.png" className="logo" alt="logo" />
                     </Link>
                     <div className="text-dark font-w700 font-size-h2 mb-2">Great decision, let’s get you started!</div>
                     <div className="d-flex justify-content-center m-auto" style={{ maxWidth: 650 }}>
                        <span className="font-size-h5">Already have an account?
                           <Link to="/login" className="px-2">Sign in here.</Link>
                        </span>
                     </div>
                  </div>
                  {/* END Header */}
                  {/* Sign Up Form */}
                  <div className="row no-gutters justify-content-center">
                     <div className="col-sm-8 col-xl-6">
                        <div className="py-3">
                           <div className="form-group">
                              <label className="font-w700 font-size-h4">About You</label>
                              <div className="d-flex">
                                 <input type="text" className="form-control mr-3" placeholder="First Name" value={firstName} onChange={(ev) => setFirstName(ev.target.value)} />
                                 <input type="text" className="form-control" placeholder="Last Name" value={lastName} onChange={(ev) => setLastName(ev.target.value)} />
                              </div>
                           </div>
                           <div className="form-group">
                              <input type="email" className="form-control" placeholder="Your Email" value={email} onChange={(ev) => setEmail(ev.target.value)} />
                           </div>
                           <div className="form-group">
                              <input type="password" className="form-control" placeholder="Password" value={password} onChange={(ev) => setPassword(ev.target.value)} />
                           </div>
                        </div>
                        <div className="form-group">
                           <button type="submit" className="btn btn-block btn-hero-lg btn-hero-primary" disabled={loading} onClick={onHandleSubmit}>
                              {
                                 loading ?
                                    <i className="fa fa-fw fa-circle-notch fa-spin mr-1" />
                                    : <i className="fa fa-fw fa-plus mr-1" />
                              }
                              Create Account
                           </button>
                           <p className="mt-4 mb-0 d-lg-flex justify-content-center">
                              By creating an account, you agree to ProTrack’s
                              <a href="#" className="px-1">Terms of Service.</a>
                           </p>
                        </div>
                     </div>
                  </div>
                  {/* END Sign Up Form */}
               </div>
            </div>
            {/* END Main Section */}
            {/* Meta InfoInColumns Section */}

            {/* END Meta InfoInColumns Section */}
         </div>
      </main>
   )
}

export default SignUp;
