import {
   UPDATE_WORKAREA_DIMENSSION
} from '../constants/ActionTypes';

const initialSettings = {
   top: 0,
   left: 0,
   width: 1000,
   height: 800,
}

export default (state = initialSettings, action) => {
   switch (action.type) {
      case UPDATE_WORKAREA_DIMENSSION:
         return {
            ...state,
            width: action.payload.width,
            height: action.payload.height
         };
      default:
         return state;
   }
}