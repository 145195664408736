import {
   REMOVE_ALL_TRACKS,
   ADD_TRACK,
   SET_TRACKS,
   SET_TRACK_COORDINATES,
   SET_TRACK_ROTATE,
   REMOVE_TRACK_BY_IDX,
   REMOVE_TRACKS_BY_IDXARR,
   ROTATE_LEFT,
   ROTATE_RIGHT,
   MOVE_DOWN,
   MOVE_UP,
   MOVE_LEFT,
   MOVE_RIGHT,
} from '../constants/ActionTypes';

const initialSettings = [];

export default (state = initialSettings, action) => {
   switch (action.type) {
      case REMOVE_ALL_TRACKS:
         return initialSettings;
      case ADD_TRACK:
         return [...state, action.payload];
      case SET_TRACKS:
         return action.payload;
      case SET_TRACK_COORDINATES:
         return state.map((track, index) => {
            const { idx, xCoord, yCoord } = action.payload;
            if (index === idx) {
               return { ...track, x: xCoord, y: yCoord }
            } else return track
         });
      case SET_TRACK_ROTATE:
         return state.map((track, index) => {
            const { idx, rot } = action.payload;
            if (index === idx) {
               return { ...track, r: rot }
            } else return track
         });
      case REMOVE_TRACK_BY_IDX:
         return state.filter((track, index) => {
            if (index === action.payload) return false;
            else return true;
         });
      case REMOVE_TRACKS_BY_IDXARR:
         const idxArr = action.payload;
         const fTracks = state.filter((track, index) => {
            if (idxArr.includes(index)) return false;
            else return true;
         });
         return fTracks;
      case ROTATE_LEFT:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, r: track.r - 22.5 };
            else return track;
         });
      case ROTATE_RIGHT:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, r: track.r + 22.5 };
            else return track;
         });
      case MOVE_DOWN:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, y: track.y + 1 };
            else return track;
         });
      case MOVE_UP:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, y: track.y > 1 ? track.y - 1 : track.y };
            else return track;
         });
      case MOVE_LEFT:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, x: track.x - 1 };
            else return track;
         });
      case MOVE_RIGHT:
         return state.map((track, index) => {
            if (index === action.payload) return { ...track, x: track.x + 1 };
            else return track;
         });
      default:
         return state;
   }
}