// Sync const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

// Auth const
export const SIGNUP_USER = 'signup_user';
export const SIGNUP_USER_SUCCESS = 'signup_user_success';
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const AUTH_USER_DATA = 'auth_user_data';
export const USER_TOKEN_SET = 'user_token_set';

// AlertMessage
export const SHOW_EXAMPLE_IGNORE_MESSAGE = "show_example_ignore_message";
export const HIDE_EXAMPLE_IGNORE_MESSAGE = "hide_example_ignore_message";

// Tracks
export const REMOVE_ALL_TRACKS = "remove_all_tracks";
export const ADD_TRACK = "add_track";
export const SET_TRACKS = "set_tracks";
export const SET_TRACK_COORDINATES = "set_track_coordinates";
export const SET_TRACK_ROTATE = "set_track_rotate";
export const REMOVE_TRACK_BY_IDX = "remove_track_by_idx";
export const REMOVE_TRACKS_BY_IDXARR = "remove_tracks_by_idxarr";

// Designs
export const GET_ALL_DESIGNS = "get_all_designs";
export const REMOVE_DESIGN = "remove_design";

// Floorplan
export const UPDATE_FLOORPLAN_VISIBLE = "update_floorplan_visible";
export const UPDATE_FLOORPLAN_EDITABLE = "update_floorplan_editable";
export const UPDATE_FLOORPLAN_COORDINATES = "update_fllorplan_coordinates";

// Zoom
export const ZOOM_IN = "zoom_in";
export const ZOOM_OUT = "zoom_out";
export const RESET_ZOOM = "rest_zoom";

// Grid
export const ENABLE_GRID = "enable_grid";
export const DISABLE_GRID = "diable_grid";
export const TOGGLE_GRID = "toggle_grid";

// Rotate
export const ROTATE_LEFT = "rotate_left";
export const ROTATE_RIGHT = "rotate_right";

//Move
export const MOVE_DOWN = "move_down";
export const MOVE_UP = "move_up";
export const MOVE_LEFT = "move_left";
export const MOVE_RIGHT = "move_right";

// WorkArea
export const UPDATE_WORKAREA_DIMENSSION = "update_workarea_dimenssion"

// Inventory
export const SET_FILTER_CATEGORY = "set_filter_cateogry";

export const SET_INDICATED_TRACK_IDX = "set_indicated_track_idx";