import {
   GET_ALL_DESIGNS,
   REMOVE_DESIGN,
} from '../constants/ActionTypes';

const initialSettings = [];

export default (state = initialSettings, action) => {
   switch (action.type) {
      case GET_ALL_DESIGNS:
         return action.payload;
      case REMOVE_DESIGN:
         return state.filter(course => {
            return course._id !== action.payload;
         });
      default:
         return state;
   }
}