import {
   ZOOM_IN,
   ZOOM_OUT,
   RESET_ZOOM,
   ENABLE_GRID,
   DISABLE_GRID,
   TOGGLE_GRID,
} from '../constants/ActionTypes';

export const initialSettings = {
   zoomRate: 1,
   isGirdEnabled: true,
}

export default (state = initialSettings, action) => {
   switch (action.type) {
      case ZOOM_IN:
         return {
            ...state,
            zoomRate: state.zoomRate < 2 ? state.zoomRate + 0.05 : state.zoomRate
         };
      case ZOOM_OUT:
         return {
            ...state,
            zoomRate: state.zoomRate > 0.4 ? state.zoomRate - 0.05 : state.zoomRate
         };
      case RESET_ZOOM:
         return {
            ...state,
            zoomRate: 1
         };
      case ENABLE_GRID:
         return {
            ...state,
            isGirdEnabled: true,
         }
      case DISABLE_GRID:
         return {
            ...state,
            isGirdEnabled: false,
         }
      case TOGGLE_GRID:
         return {
            ...state,
            isGirdEnabled: !state.isGirdEnabled,
         }
      default:
         return state;
   }
}