import trackAssets from "../constants/Assets"
import { toast } from 'react-toastify';
import _ from "lodash";

export const trackDataFromId = (trackId) => {
   const match = _.find(trackAssets, function (o) {
      return o.id === trackId;
   });
   return match ? match : null;
}

export const ToastErrorNotification = (errors) => {
   const errKeys = Object.keys(errors);
   errKeys.map(err => {
      const errMsg = `${err} ${errors[err]}`;
      toast.success(errMsg.charAt(0).toUpperCase() + errMsg.slice(1))
   });
}
