import {
   UPDATE_FLOORPLAN_VISIBLE,
   UPDATE_FLOORPLAN_EDITABLE,
   UPDATE_FLOORPLAN_COORDINATES,
} from '../constants/ActionTypes';

export const initialSettings = {
   isEditable: false,
   isVisible: true,

   initialTop: 100,
   initialLeft: 100,

   top: 100,
   left: 100,
   width: 800,
   height: 600,
}

export default (state = initialSettings, action) => {
   switch (action.type) {
      case UPDATE_FLOORPLAN_VISIBLE:
         return {
            ...state,
            isVisible: action.payload
         };
      case UPDATE_FLOORPLAN_EDITABLE:
         return {
            ...state,
            isEditable: action.payload
         };
      case UPDATE_FLOORPLAN_COORDINATES:
         return {
            ...state,
            ...action.payload
         };
      default:
         return state;
   }
}