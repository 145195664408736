import React from 'react';
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import configureStore from "./store";
import App from "./App";

import 'react-toastify/dist/ReactToastify.css';
import './styles/global.css';

export const store = configureStore();

console.log("process.env.NODE_ENV ===>", process.env.NODE_ENV)

const MainApp = () => {
   return (
      <Provider store={store}>
         <Router>
            <Switch>
               <Route path="/" component={App} />
            </Switch>
         </Router>
         <ToastContainer autoClose={3000} />
      </Provider>
   );
}

export default MainApp;
