import {
   SET_FILTER_CATEGORY,
} from '../constants/ActionTypes';

export const initialSettings = {
   filterCategory: null,
}

export default (state = initialSettings, action) => {
   switch (action.type) {
      case SET_FILTER_CATEGORY:
         return {
            ...state,
            filterCategory: action.payload
         };
      default:
         return state;
   }
}