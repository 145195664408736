import {
   SET_INDICATED_TRACK_IDX
} from '../constants/ActionTypes';

const initialSettings = {
   indicatedTrackIdx: null
}

export default (state = initialSettings, action) => {
   switch (action.type) {
      case SET_INDICATED_TRACK_IDX:
         return {
            ...state,
            indicatedTrackIdx: action.payload
         };
      default:
         return state;
   }
}